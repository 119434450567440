import axios from "axios";

const instance = axios.create({
  // baseURL: "http://13.215.159.190:8080",
  baseURL: "https://aryaf.salonatkw.com/aryaf",
  // baseURL: "https://api.aryaf-perfumes.com/:8080",
  // baseURL: "http://localhost:8080",
});

export default instance;
